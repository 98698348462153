<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title>{{ $moment(leaveRequest.date).format("DD MMMM YYYY") }}</v-list-item-title>
    </v-list-item-content>
    <v-list-item-action>
      <v-list-item-action-text v-if="leaveRequest.leaveRequestDayType === 'ON_LEAVE'">
        {{ $t(`leave_duration.${leaveRequest.duration}`, [$t("leave.on_leave")]) }}
      </v-list-item-action-text>
      <v-list-item-action-text v-else>
        {{
          $t("leave.request.this_day_does_not_count_as_leave", [$t(`shift_types.${leaveRequest.leaveRequestDayType}`)])
        }}
      </v-list-item-action-text>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description leave-request-item Component Logic
   * @date 18.08.2020
   */

  import LeaveRequestDay from "../model/LeaveRequestDay";

  export default {
    name: "leave-request-item",
    props: {
      leaveRequest: {
        type: LeaveRequestDay,
        required: true
      }
    }
  };
</script>

<style scoped>
  .v-list-item {
    padding: 0 !important;
  }
</style>
